import Sentry from '@sentry/browser';

const config = window.__CONFIG__;

// process.env.ARTIFACT_ID and process.env.ARTIFACT_VERSION are automatically
// replaced by yoshi during the build phase, so this code should be inside js/ts file.
Sentry.init({
  dsn: 'https://62c19294336f41aab6c997ede4670ef8@sentry-next.wixpress.com/662',
  release: [process.env.ARTIFACT_ID, process.env.ARTIFACT_VERSION].join('@'),
  environment: config.environment,
  enabled: true,
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'pktAnnotationHighlighter',
    '__puppeteer_evaluation_script__',
    '@safari-extension',
    'vid_mate_check is not defined',
    'ethereum',
    'Non-Error promise rejection captured with value: Object Not Found Matching',
  ],
});

Sentry.configureScope(function (scope) {
  scope.setUser({ id: config.userId });
  scope.setContext('campaign', {
    campaignId: config.messageId,
    metaSiteId: config.metaSiteId,
    locale: config.locale,
  });
});
